@import 'abstracts/variables';

.cart-icon {
  width: auto;

  &.size-xl {
    height: 48px;
  }

  &.size-md {
    height: 30px;
  }

  &.size-sm {
    height: 26px;
  }
}

.mobile-cart-popover {
  overflow-x: hidden;

  :global {
    .rs-modal-dialog {
      .rs-modal-content {
        height: auto !important; // Overriding basemodal styles
        margin: @spacer * 1.5;
        text-align: left;
        padding: 0 !important;
      }
    }

    .rs-modal-header {
      padding: @spacer * 2;
      padding-bottom: 0;
    }

    .rs-modal-body {
      padding-bottom: 0;
      margin: 0;
    }

    .rs-modal-footer {
      padding: @spacer * 2;
      padding-top: 0;

      @media @below-md {
        padding-bottom: @spacer;
      }

      button {
        height: 50px;
        font-size: @font-size-large;
      }
    }
  }
}

.cart-icon-container {
  position: relative;

  .counter {
    :global(.rs-badge-content) {
      position: absolute;
      top: unset;
      bottom: -10px;
      right: 9px;
      background-color: @primary-dark;
      color: @dim-darker;
      font-weight: 600;
      padding: 1px 6px; // To match badge padding in figma
      border-radius: 50%;
    }

    &.hover-style {
      &:hover {
        path {
          stroke: @primary-darker;
        }
      }
    }
  }
}

.mobile-cart-popover-wrapper + div {
  align-content: center;
}
