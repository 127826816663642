@import 'abstracts/variables';
@import 'abstracts/helpers';

.apply-form {
  :global {
    .rs-form-control-wrapper {
      width: 100%;

      input {
        width: 100%;
      }
    }
  }
}

.address-dropdown {
  :global {
    .rs-picker-select-menu-item {
      &:hover {
        font-weight: 600;
        background: transparent;
      }
    }

    .rs-picker-select-menu-item.rs-picker-select-menu-item-active {
      background: transparent;
    }
  }
}

// Used for rsuite form controller dropdown fields in readonly mode
.dropdown-pointer-cursor > div {
  cursor: pointer !important;
}

.selected-value {
  :global {
    .rs-picker-toggle-value {
      color: @primary-darker !important;
    }
  }
}

.custom-form {
  .continue-button {
    @media @below-sm {
      .button-fixed-bottom;

      margin: 0 !important;
    }
  }

  input:placeholder-shown {
    text-overflow: ellipsis;
  }

  textarea {
    min-height: 80px; // Ensure textarea fits at least 3 text rows by default
  }

  .textarea-height-lg,
  .textarea-height-lg textarea {
    min-height: 110px;
  }

  :global {
    .rs-picker-toggle .rs-picker-toggle-placeholder {
      font-weight: 400;
    }
  }

  :global(.rs-form-error-message-wrapper) {
    position: relative;

    :global {
      .rs-form-error-message {
        position: relative;
        margin-top: @spacer;
        border: none;
        padding: 0;
        background: none;
        text-align: left;
      }

      .rs-form-error-message-arrow {
        display: none;
      }

      .rs-form-error-message-inner {
        white-space: initial;
      }
    }
  }

  .radio-button-group,
  .checkbox-button-group {
    :global {
      .rs-radio-checker,
      .rs-checkbox-checker {
        label {
          font-weight: 400;
        }
      }
    }
  }

  .phone-number-form-group {
    span {
      font-weight: 400;
    }
  }
}

.number-input {
  margin: 0;

  input:focus {
    z-index: 1;
  }

  :global(.rs-input-group) {
    max-width: 150px;
  }

  :global(.rs-input-number-btn-group-vertical) {
    display: none;
  }

  // Align rsuite input native increment and decrement buttons around input field
  &.increment-buttons {
    position: relative;

    :global {
      .rs-input-number {
        display: flex;
        width: 100%;

        .rs-input-group-addon {
          display: none;
        }

        input {
          text-align: center;
          flex: unset;
          width: 50%; // input width + (button width * 2) = 100%
          margin: 0 auto;
        }

        button {
          width: 25%;
          height: 100%;
          border-radius: 0;
        }

        .rs-input-number-btn-group-vertical {
          display: flex;
          justify-content: space-between;
          flex-direction: row-reverse;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;

          // Hide the original buttons
          .rs-input-number-touchspin-up svg,
          .rs-input-number-touchspin-down svg {
            display: none;
          }

          .rs-input-number-touchspin-up::before {
            content: '+';
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            background: @B050;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iNS4yNSIgeT0iMC43NSIgd2lkdGg9IjEuNSIgaGVpZ2h0PSIxMC41IiByeD0iMC43NSIgZmlsbD0iIzhFOEU5MyIvPgo8cmVjdCB4PSIwLjc1IiB5PSI1LjI1IiB3aWR0aD0iMTAuNSIgaGVpZ2h0PSIxLjUiIHJ4PSIwLjc1IiBmaWxsPSIjOEU4RTkzIi8+Cjwvc3ZnPgo=');
            background-repeat: no-repeat;
            background-position: center center;
          }

          .rs-input-number-touchspin-down::after {
            content: '';
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            background: @B050;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC43NSIgeT0iNS4yNSIgd2lkdGg9IjEwLjUiIGhlaWdodD0iMS41IiByeD0iMC43NSIgZmlsbD0iIzhFOEU5MyIvPgo8L3N2Zz4K');
            background-repeat: no-repeat;
            background-position: center center;
          }
        }

        &.rs-input-group-focus {
          outline: 3px solid var(--rs-color-focus-ring); // Use rsuite focus for entire element

          input {
            outline: none;
          }
        }
      }
    }
  }
}

.number-input-children {
  display: flex;
  align-items: center;
  gap: @spacer * 1.5;

  > div {
    width: unset !important;
  }
}

.error {
  color: var(--rs-form-errormessage-text);
  font-size: @font-size-small;
}

.error > div {
  border-color: @red !important;
}

.mobile-dropdown > div {
  cursor: pointer !important;
}

.email-domain-warning {
  max-width: 310px;
  color: @orange;
  font-size: @font-size-small;
  margin-top: @spacer * 0.5;
  text-align: left;
}

.character-count {
  color: @B600;
  font-weight: 400;

  &.error {
    color: @red;
  }
}
